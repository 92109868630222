<template>
  <div :class="`modal image-preview-modal ${isActive ? 'is-active' : ''}`">
    <img src="@assets/icons/close-white.svg" alt="close" class="icon-close is-clickable" @click="closeModal">
    <div class="modal-background is-clickable" @click="closeModal"></div>
    <div class="modal-content">
      <img class="property-update-image" alt="property update image" :src="imgUrl">
    </div>
  </div>
</template>
<script>

export default {
  props: {
    imgUrl: {
      type: String,
      required: true,
      default: ''
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  created() {
    this.isActive = !!this.imgUrl
    document.body.addEventListener('keydown', this.pressEscToCloseModal)
  },
  mounted() {
  },
  beforeDestroy() {
    document.body.removeEventListener('keydown', this.pressEscToCloseModal)
  },
  watch: {
    imgUrl(newValue) {
      this.isActive = !!newValue
    }
  },
  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('closeModal')
    },
    pressEscToCloseModal(e) {
      e.preventDefault()
      if (e.key === 'Escape') this.closeModal()
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.image-preview-modal {
  z-index: 10;
  .icon-close {
    width: 18px;
    position: absolute;
    left: 2vh;
    top: 2vh;
    z-index: 101;
  }
  .modal-content {
    width: unset;
    height: unset;
    max-height: unset;
    position: relative;
    margin: 0;
    .property-update-image {
      max-width: 97vw;
      max-height: 90vh;
      object-fit: contain;
    }
  }
}
</style>
