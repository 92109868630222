<template>
  <div class="property-update-details">
    <div class="sticky-header has-background-white" v-if="$isMobile">
      <MenuAndLogo :showReferral="true" :hasNavBack="true" :action="navBack"></MenuAndLogo>
    </div>
    <NavBack :action="navBack"/>
    <div class="scrolling-part">
      <div class="page-header has-text-centered">
        <div class="property-nickname has-text-weight-semibold">{{propertyNickname}}</div>
        <div class="property-name">{{propertyName}}</div>
      </div>
      <div class="type-tag is-inline-block has-text-weight-semibold has-text-blue has-text-centered">{{updateType}}</div>
      <div class="update-title has-text-weight-semibold">{{updateTitle}}</div>
      <div class="update-date">{{updateDate | formatDate}}</div>
      <div class="content update-text" v-html="updateText"></div>
      <div class="update-assets" v-if="updateAssets.length">
        <div class="swiper-box" v-if="updateAssets.length > 1">
          <div class="assets-swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(asset, index) in updateAssets"
                :key="index"
                class="swiper-slide">
                  <div
                    :style="`background: url(${asset.url}) center/cover no-repeat;`"
                    class="image-block is-clickable"
                    @click="showImageUrl = asset.url"
                    v-if="asset.type === 'image'"
                  ></div>
                  <iframe :src="asset.url" frameborder="0" v-else></iframe>
                </div>
            </div>
          </div>
          <div class="arrows is-flex is-justify-content-center">
            <img src="@assets/icons/arrow-left.svg" alt="arrow-left" :class="['arrow-left', { 'arrow-disabled': !hasPrev }]" @click="slidePrev">
            <img src="@assets/icons/arrow-right.svg" alt="arrow-right" :class="['arrow-right', { 'arrow-disabled': !hasNext }]" @click="slideNext">
          </div>
        </div>
        <div class="has-text-centered has-one-asset-only" v-else>
          <div
            :style="`background: url(${updateAssets[0].url}) center/cover no-repeat;`"
            class="image-block is-clickable"
            @click="showImageUrl = updateAssets[0].url"
            v-if="updateAssets[0].type === 'image'"
          ></div>
          <iframe :src="updateAssets[0].url" frameborder="0" v-else></iframe>
        </div>
      </div>
      <div class="update-attachments">
        <div class="attachment is-relative is-clickable" v-for="(file, index) in attachments" :key="index" @click="getFileUrl(file)">
          <div class="file-info is-flex is-align-items-center">
            <img src="@assets/icons/file.svg" alt="file" class="file-icon">
            <div>
              <div class="name">{{file.filename}}</div>
              <div class="size">{{file.size}} <span class="is-uppercase">{{file.extension}}</span> file</div>
            </div>
          </div>
          <img src="@assets/icons/view.svg" alt="view" class="view-icon">
        </div>
      </div>
    </div>
    <ImagePreviewModal :imgUrl="showImageUrl" @closeModal="showImageUrl = ''"></ImagePreviewModal>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import NavBack from '@components/desktop/header-nav-back.vue'
import ImagePreviewModal from '@components/modal/image-preview-modal.vue'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { fetchPropertyUpdateDetails } from '@api/portfolio'
import { fetchDocUrl } from '@api/common'
import { embeddedInIos } from '@utils/common-methods/common'
import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      updateType: '',
      updateTitle: '',
      updateDate: '',
      updateText: '',
      updateAssets: [],
      assetsSwiper: undefined,
      attachments: [],
      showImageUrl: '',
    }
  },
  components: {
    MenuAndLogo,
    NavBack,
    ImagePreviewModal,
  },
  mounted() {
    this.getDetails()
  },
  filters: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('MMMM d, yyyy')
    },
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    propertyNickname() {
      return this.decodeQuery(this.$route.query.nickname)
    },
    propertyName() {
      return this.decodeQuery(this.$route.query.propertyName)
    },
    embeddedByIos() {
      return embeddedInIos()
    },
    hasPrev() {
      return this.assetsSwiper && !this.assetsSwiper.isBeginning
    },
    hasNext() {
      return this.assetsSwiper && !this.assetsSwiper.isEnd
    },
    nameSpaceUrl() {
      return this.$route.params.nameSpaceUrl
    },
  },
  methods: {
    getDetails() {
      fetchPropertyUpdateDetails(
        this.$route.params.propertyId,
        this.$route.params.updateId,
      ).then((data) => {
        if (!data.success) return
        Object.assign(this, data.data.propertyUpdate)
        this.$nextTick(() => {
          this.updateAssets.length && this.initializeCarousel()
        })
      })
    },
    initializeCarousel() {
      this.assetsSwiper = new Swiper('.assets-swiper-container', {
        initialSlide: 0,
      })
      this.assetsSwiper.on('activeIndexChange', () => {
        this.mixpanel.track('2zjsyz3n')
      })
    },
    slidePrev() {
      if (this.assetsSwiper.isBeginning) return
      this.assetsSwiper.slidePrev()
    },
    slideNext() {
      if (this.assetsSwiper.isEnd) return
      this.assetsSwiper.slideNext()
    },
    async getFileUrl(file) {
      let w
      if (!this.embeddedByIos) w = window.open('')
      const response = await fetchDocUrl({
        model_name: file.modelName,
        hashid: file.id,
        document_type: file.docType,
      })
      if (!this.embeddedByIos && (!response.success || response.data.url === null)) {
        return w.close()
      }
      if (!this.embeddedByIos) w.location.href = response.data.url
      else response.data.url && window.webkit.messageHandlers.openURL.postMessage(response.data.url)
    },
    decodeQuery(url) {
      let query
      try {
        query = decodeURIComponent(url)
      } catch (error) {
        query = url
      }
      return query
    },
    navBack() {
      this.$router.replace(`/portfolio/${this.nameSpaceUrl}/property/${this.$route.params.propertyId}`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

#app.is-embedded-in-ios > div:first-child.property-update-details {
  .scrolling-part { height: unset }
}

.property-update-details {
  padding-top: 70px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  .sticky-header {
    width: 100%;
    height: 68px;
    box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .page-header {
    width: 100%;
    padding: 10px 6px;
    .property-nickname {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 4px;
    }
    .property-name {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .scrolling-part {
    height: calc(100vh - 100px);
    height: calc(var(--vh, 1vh) * 100 - 100px);
    padding: 0 0 80px;
    overflow: auto;
    .type-tag {
      padding: 7px 11px;
      background: rgba(240, 238, 248, 0.5);
      border-radius: 6px;
      font-size: 14px;
      line-height: 17px;
      margin-top: 16px;
      margin-left: 25px;
    }
    .update-title {
      padding: 0 25px;
      font-size: 16px;
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 4px;
    }
    .update-date {
      padding: 0 25px;
      font-size: 14px;
      line-height: 17px;
      color: #9185AE;
    }
    .update-text {
      padding: 0 25px;
      font-size: 16px;
      line-height: 19px;
      margin-top: 12px;
      margin-bottom: 18px;
      ::v-deep a{
        font-weight: 600;
        color: #9185AE;
      }
      ::v-deep b{
        font-weight: bold;
      }
      ::v-deep i{
        font-style: italic;
      }
    }
    .update-assets {
      overflow: hidden;
      .has-one-asset-only {
        padding: 0 25px;
        height: 180px;
        div,
        iframe {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }
      .swiper-slide {
        height: 180px;
        padding: 0 25px;
        .image-block {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
        iframe {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }
      .arrows {
        img {
          padding: 17px 4px;
          cursor: pointer;
          &.arrow-disabled{
            opacity: 0.5;
            pointer-events: none;
          }
          &.arrow-left {
            margin-right: 50px;
          }
        }
      }
    }
    .update-attachments {
      margin-top: 20px;
      padding: 0 25px;
      .attachment {
        height: 56px;
        background-color: #F0EEF8;
        border-radius: 4px;
        margin-bottom: 8px;
        .file-info {
          height: 100%;
          .file-icon {
            width: 14px;
            height: 16px;
            margin-left: 18px;
            margin-right: 17px;
          }
          .name {
            font-size: 14px;
            line-height: 20px;
          }
          .size,
          .size span {
            color: #9185AE;
          }
        }
        .view-icon {
          padding: 10px;
          position: absolute;
          right: 14px;
          top: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .property-update-details {
    padding-top: 0;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    height: 100% !important;
    .page-header {
      box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    }
    .scrolling-part {
      height: unset;
    }
  }
}
</style>
