import { render, staticRenderFns } from "./image-preview-modal.vue?vue&type=template&id=479cb72b&scoped=true&"
import script from "./image-preview-modal.vue?vue&type=script&lang=js&"
export * from "./image-preview-modal.vue?vue&type=script&lang=js&"
import style0 from "./image-preview-modal.vue?vue&type=style&index=0&id=479cb72b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479cb72b",
  null
  
)

export default component.exports