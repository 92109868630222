import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Get Public Portfolio Token
 * @param {Object} payload buy_id
 * @return {Promise}
 */
export const getPublicPortfolioToken = (payload) => {
  return axios.get(getImbyRoute('/api/v1/portfolios/public_portfolio_token'), { params: payload })
}

/**
 * Get Portfolio Details
 * @param {Object} payload buy_id
 * @return {Promise}
 */
export const getPortfolioDetails = (payload) => {
  return axios.get(getImbyRoute('/api/v1/portfolios/portfolio_details'), { params: payload })
}

/**
 * Get Public Portfolio Details
 * @param {Object} payload jwt
 * @return {Promise}
 */
export const getPublicPortfolioDetails = (payload) => {
  return axios.get(getImbyRoute(`/api/v1/public/portfolios/investment_details?jwt=${payload}`))
}

/**
 * Investment Summary in Portfolio
 * @param {String} country Canada | United_States
 * @return {Promise}
 */
export const getInvestmentSummary = (country = 'Canada') => {
  return axios.get(getImbyRoute('/api/v1/portfolios/investments_summary'), { params: { country } })
}

/**
 * metaverse
 * @return {Promise}
 */
export const getMetaverseData = () => {
  return axios.get(getImbyRoute('/api/v1/portfolios/metaverse/city_details'))
}

/**
 * Metaverse: Update City Name
 * @param {Object} payload city_name
 * @return {Promise}
 */
export const updateCityName = (payload) => {
  return axios.put(getImbyRoute('/api/v1/portfolios/metaverse/city_name_update'), payload)
}

/**
 * Get Investment List
 * @param {String} country Canada | United_States
 * @return {Promise}
 */
export const getInvestmentList = (country = 'Canada') => {
  return axios.get(getImbyRoute('/api/v1/portfolios/investments_preview_details'), { params: { country } })
}

/**
 * Get Investments Summary
 * @param {String} id propertyId
 * @param {String} payload { distribution_id }
 * @return {Promise}
 */
export const getInvestmentsSummary = (id, payload) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${id}/investments_summary`), { params: payload })
}

/**
 * Get Investments Summary
 * @param {String} id propertyId
 * @return {Promise}
 */
export const getPropertyRelatedTransactions = (id) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${id}/transactions`))
}

/**
 * Get Updates List
 * @param {String} id propertyId
 * @return {Promise}
 */
export const getPropertyUpdates = (id) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${id}/updates`))
}

/**
 * Update Property Updates Flags
 * @param {String} propertyId Property Id
 * @param {String} updateId Property Update Id
 * @return {Promise}
 */
export const updatePropertyUpdatesFlags = (propertyId, updateId) => {
  return axios.post(getImbyRoute(`/api/v1/properties/${propertyId}/updates/${updateId}/views`))
}

/**
 * Fetch Property Update Details
 * @param {String} propertyId
 * @param {String} updateId
 * @return {Promise}
 */
export const fetchPropertyUpdateDetails = (propertyId, updateId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${propertyId}/updates/${updateId}`))
}

/**
 * Get Legal Documents Status
 * @param {String} propertyId
 * @return {Promise}
 */
export const getLegalDocumentsStatus = (propertyId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${propertyId}/investment_document_status`))
}

/**
 * Cancel Purchase
 * @param {String} propertyId
 * @param {String} buyId
 * @param {String} reason
 * @return {Promise}
 */
export const cancelPurchase = (propertyId, buyId, reason) => {
  return axios.put(getImbyRoute(`/api/v1/properties/${propertyId}/buys/${buyId}/cancel`), { reason })
}
